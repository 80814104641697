export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "user",
				bullet: "dot"
			},
			{
				title: "Eletropostos",
				root: true,
				icon: "fas fa-charging-station",
				page: "eletropostos",
				bullet: "dot"
			},
			{
				title: "Carros",
				root: true,
				icon: "fas fa-car",
				page: "carros",
				bullet: "dot"
			},
			{
				title: "Valores de Referência",
				root: true,
				icon: "fas fa-table",
				page: "referencia",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Relatório de recargas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Erros reportados",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
					{
						title: "Relatório de agendamentos",
						bullet: "dot",
						page: "relatorio/relatorio03"
					},
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
			{
				title: "OCPP 1.6 Teste",
				root: true,
				icon: "fas fa-wifi",
				page: "ocpp",
				bullet: "dot"
			},
		]
	}
};
